import { AiFillPauseCircle, AiFillPlayCircle, AiFillStop, AiOutlineLoading3Quarters, BiCopy, BiEdit, BiRedo, BiX, BsTrash, Button, FaEdit, FaSpinner, TextInputArea, toast } from "@acme/ui"
import { type ChatMessage, generateChatContentString, type ChatMessageTextContent, dayjs } from "@acme/util"
import { useAtom, useSetAtom } from "jotai"
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { chatRegenerateMessageAtom, chatRemoveMessageAtom, chatUpdateMessageAtom } from "../store/chat"
import dynamic from "next/dynamic"
import { useTextToSpeech } from "../hooks/useTextToSpeech"
import { textToSpeechSettingsAtom } from "../store/setting"

type Props = {
  id: string
  sessionId: string
  isMe?: boolean
  role?: ChatMessage['senderRole']
  name?: string
  pic?: string
  created?: Date
  updated?: Date
  isGenerating?: boolean
  isLoading?: boolean
  message: ChatMessageTextContent
  messageCustoms?: ChatMessage['contentCustoms']
  loadingText?: string

  abortCtrl?: AbortController | null
}

const TextMarkDown = dynamic(() => import("@acme/ui").then(a => a.TextMarkDown), { ssr: false })

export const ChatBubble = ({ id, sessionId, isMe = false, updated, role = 'user', name = '', pic = '', created, message, isLoading = false, isGenerating = false, loadingText = '', messageCustoms, abortCtrl }: Props) => {
  const onDeleteMessage = useSetAtom(chatRemoveMessageAtom)
  const onUpdateMessage = useSetAtom(chatUpdateMessageAtom)
  const onRegenerateMessage = useSetAtom(chatRegenerateMessageAtom)
  const [isEdit, setIsEdit] = useState(false)
  // combine and separate by \n if array
  const [contentTemp, setContentTemp] = useState<string>(Array.isArray(message.content) ? message.content.join('\n') || '' : message.content || '')

  const messageFixed = useMemo(() => {
    return generateChatContentString(message)
  }, [message])

  const onCopy = useCallback(async () => {
    await navigator.clipboard.writeText(messageFixed)
    toast.success('Copied to clipboard')
  }, [messageFixed])

  const onDelete = useCallback(async () => {
    onDeleteMessage(id, sessionId || '')
  }, [id, sessionId, onDeleteMessage])

  const onSave = useCallback(async (value: string, isGenerate?: boolean, contentCustoms?: ChatMessage['contentCustoms']) => {
    // Should check sync
    onUpdateMessage({ id, content: { content: value, contentType: 'text' }, ...contentCustoms ? { contentCustoms } : {} })
    setIsEdit(false)
    if (isGenerate) {
      await onRegenerateMessage({ sessionId, messageId: id, messageContent: { contentType: 'text', content: value } })
    }
  }, [id, sessionId, onUpdateMessage, onRegenerateMessage])

  const onCancel = useCallback(() => {
    setIsEdit(false)
    if (abortCtrl?.abort) {
      abortCtrl.abort()
    }
    onUpdateMessage({ id, loading: false, generating: false }, { isSkip: true })
    if (!message?.content && id) {
      onDeleteMessage(id, sessionId, true)
    }
  }, [abortCtrl, id, message, onDeleteMessage, onUpdateMessage, sessionId])

  const messageAgo = useMemo(() => {
    if (!created) return { date: '' }
    if (created && updated) {
      // check if updated newer
      if (dayjs(updated).isAfter(created)) {
        return { date: dayjs(updated).fromNow(), isUpdated: true }
      }
      return { date: dayjs(created).fromNow() }
    }
    return { date: dayjs(created).fromNow() }
  }, [created, updated])

  useEffect(() => {
    if (isEdit) {
      setContentTemp(Array.isArray(message.content) ? message.content.join('\n') || '' : message.content || '')
    }
  }, [isEdit, message])

  if (role === 'system') {
    const systemMessagedLimit = messageFixed.length > 200 ? messageFixed.substring(0, 200) + '...' : messageFixed
    return (
      <div id={id} className="p-8 rounded shadow-sm py-4 px-4 lg:px-24 xl:px-40 group relative">
        <div className="flex flex-row items-start gap-5">
          <div className="w-full relative">
            {/* <p>Response</p> */}
            <div className="bg-gray-400 text-center text-gray-700 text-xs p-2 rounded-xl">
              <p>{systemMessagedLimit}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div id={id} className={`py-2 px-4 group relative transition duration-200 border-b border-b-gray-100 dark:border-b-neutral-600 dark:border-opacity-40`}>
      <div className="md:max-w-2xl lg:max-w-2xl xl:max-w-3xl mx-auto relative">
        <div className="flex flex-row items-start gap-3">
          {/* <div className="w-8 overflow-hidden">
          <div className="w-7 h-7 rounded bg-blue-300" />
        </div> */}
          <div className={`${isEdit ? 'w-full' : 'w-auto'} max-w-full relative flex`}>
            {/* <p>Response</p> */}
            <div className={`${isEdit ? 'w-full' : 'w-full'} relative text-[15px] ${role === 'user' ? 'bg-gray-200 text-neutral-900 dark:text-white dark:bg-[#0b141a] dark:old-bg-[#1D282F]' : 'text-gray-800 dark:text-gray-100'} leading-7 p-4 py-3 rounded-r-lg rounded-b-lg`}>
              {isLoading ? (
                <div className="animate-pulse text-sm flex flex-row gap-3 items-center">
                  <AiOutlineLoading3Quarters className="animate-spin" />
                  <p>{loadingText || 'Generating response....'}</p>
                </div>
              ) : isEdit ? (
                <>
                  <ChatBubbleEdit
                    role={role}
                    value={contentTemp}
                    valueCustoms={messageCustoms}
                    setIsEdit={setIsEdit}
                    onSave={onSave}
                  />
                </>
              ) : (
                <>
                  <TextMarkDown className="text-mdx">
                    {messageFixed}
                  </TextMarkDown>
                  <ChatMessageCustomPreview messageCustoms={messageCustoms} />
                </>
              )}


            </div>
          </div>
          {/* {isMe && (
          <div>
            <div className="w-10 h-10 rounded bg-blue-300" />
          </div>
        )} */}
        </div>
        <p className="text-[10px] text-gray-600 dark:text-gray-400 mt-1 ml-auto w-full text-right">{messageAgo?.isUpdated ? 'Updated' : ''} {messageAgo?.date || ''}</p>
        {
          !isEdit && (
            <>
              {role !== 'user' && (
                <ChatBubbleTextToSpeech text={contentTemp} />
              )}
              <ChatBubbleAction
                isLoading={isLoading || isGenerating}
                onCopy={onCopy}
                onDelete={onDelete}
                onEdit={() => setIsEdit(true)}
                onCancel={onCancel}
              />
            </>
          )
        }
      </div>
    </div >
  )
}

const ChatMessageCustomPreview = ({ messageCustoms }: { messageCustoms: ChatMessage['contentCustoms'] }) => {

  const messageCustomsFilterExist = useMemo(() => {
    return messageCustoms?.filter(custom => custom.content.image_url?.url)
  }, [messageCustoms])

  return (
    <>
      {messageCustomsFilterExist && (
        <div className="flex flex-row items-center gap-2">
          {messageCustomsFilterExist.map((custom, index) => (
            <div key={index}>
              <img
                src={custom.content.image_url?.url}
                alt={custom.content.image_url?.url}
                className="bg-gray-300 dark:bg-[#000000] rounded border border-gray-400 dark:border-neutral-600"
                style={{
                  width: 120,
                  height: 120,
                  objectFit: 'contain',
                  marginTop: '0.5rem',
                }}
              />
            </div>
          ))}
        </div>
      )}
    </>
  )

}

const ChatBubbleEdit = ({ value, valueCustoms, setIsEdit, onSave, role }: { value: string | string[] | null, valueCustoms?: ChatMessage['contentCustoms'], role: ChatMessage['senderRole'], setIsEdit: (val: boolean) => void, onSave: (value: string, isGenerate?: boolean, contentCustoms?: ChatMessage['contentCustoms']) => void }) => {

  const [loaded, setLoaded] = useState(false)
  const [contentTemp, setContentTemp] = useState(Array.isArray(value) ? value.join('\n') : value)
  const [valueCustomsTemp, setValueCustomsTemp] = useState<ChatMessage['contentCustoms']>(valueCustoms)
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const onRemoveImages = useCallback((index: number) => {
    setValueCustomsTemp((prev) => {
      if (!prev) return prev
      const newCustoms = [...prev];
      newCustoms.splice(index, 1);
      return newCustoms;
    })
  }, [setValueCustomsTemp])

  const onAddNewImages = useCallback(() => {
    setValueCustomsTemp((prev) => {
      if (!prev) return [{ content: { image_url: { url: '' } }, contentType: 'image_url' }]
      return [...prev, { content: { image_url: { url: '' } }, contentType: 'image_url' }]
    })
  }, [setValueCustomsTemp])

  const onUpdateImageUrl = useCallback((index: number, value: string) => {
    setValueCustomsTemp((prev) => {
      if (!prev) return prev
      const newCustoms = [...prev];
      newCustoms[index] = { content: { image_url: { url: value } }, contentType: 'image_url' }
      return newCustoms;
    })
  }, [setValueCustomsTemp])

  const onSaveFixed = useCallback((isGenerate?: boolean) => {
    if (valueCustomsTemp) {
      onSave(contentTemp || '', isGenerate, valueCustomsTemp)
    } else {
      onSave(contentTemp || '', isGenerate)
    }
  }, [contentTemp, onSave, valueCustomsTemp])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = 'inherit';
      const scrollHeight = inputRef.current.scrollHeight;
      inputRef.current.style.height = `${scrollHeight}px`;
    }
  }, [contentTemp]);

  useEffect(() => {
    if (inputRef.current && !loaded) {
      inputRef.current.focus();
      inputRef.current.selectionStart = inputRef.current.selectionEnd = inputRef.current.value.length;
      setLoaded(true)
    }
  }, [loaded]);

  useEffect(() => {
    if (valueCustoms) {
      setValueCustomsTemp(valueCustoms)
    }
  }, [valueCustoms, setValueCustomsTemp])

  useEffect(() => {
    if (value) {
      setContentTemp(Array.isArray(value) ? value.join('\n') : value)
    }
  }, [value])

  return (
    <div className="w-full">
      {/* <p>Generating resp sdjkh dsjkhf jksdh fjkdsh fjksdh fjksdh fhjkonhhjghjg ghjghjg hjgse...</p> */}
      <textarea
        value={contentTemp || ''}
        ref={inputRef}
        onChange={(e) => setContentTemp(e.target.value)}
        className="w-full h-auto bg-transparent flex-grow min-w-0 text-sm resize-none outline-none"
      />

      {!!valueCustomsTemp?.length && (
        <div className="flex flex-col items-start gap-2 mt-5 mb-6 border rounded border-gray-500 dark:border-gray-400 p-2 pb-3">
          <p className="font-semibold font-mono">Images</p>
          <div className="flex flex-col gap-3 w-full">
            {valueCustomsTemp.map((custom, index) => (
              <div key={index} className="flex flex-row items-center gap-2 w-full">
                <div
                  className="relative"
                  style={{
                    width: 50,
                    height: 50,
                    objectFit: 'contain',
                  }}
                >
                  <img
                    src={custom.content.image_url?.url}
                    alt="preview"
                    className="img-visual-preview bg-gray-300 dark:bg-[#000000] rounded border border-gray-400 dark:border-neutral-600"
                    style={{
                      width: 50,
                      height: 50,
                      objectFit: 'contain',
                    }}
                  />
                </div>
                <TextInputArea
                  value={custom.content.image_url?.url}
                  onChange={(e) => {
                    onUpdateImageUrl(index, e)
                  }}
                  className="text-xs flex-1 w-full text-gray-700"
                  containerClassName="flex-1"
                  placeholder="Image URL..."
                />

                <Button className="bg-red-500" onClick={() => onRemoveImages(index)}>
                  <BsTrash />
                </Button>
              </div>
            ))}

            <Button onClick={onAddNewImages} size="sm" className="h-7 mt-3 bg-gray-500">
              <p className="text-xs">+ Add More</p>
            </Button>
          </div>
        </div>

      )}


      <div className="flex flex-row items-center gap-2 mt-2">
        <Button onClick={() => onSaveFixed(false)} className="!bg-green-500  !py-2 text-sm">
          <FaEdit />
          <p>Save</p>
        </Button>
        {role === 'user' && (
          <Button onClick={() => onSaveFixed(true)} className="!bg-gray-500 !py-2 text-sm">
            <BiRedo />
            <p>Save & Generate</p>
          </Button>
        )}
        <Button className="!bg-gray-500  !py-2 text-sm" onClick={() => setIsEdit(false)}>
          <BsTrash />
          <p>Cancel</p>
        </Button>
      </div>
    </div>
  )
}

const ChatBubbleAction = (
  { onCopy, onDelete, onEdit, isLoading = false, onCancel }:
    { onCopy?: () => void, onDelete?: () => void, onEdit?: () => void, isLoading?: boolean, onCancel?: () => void }
) => {
  if (isLoading) {
    return (
      <div className="absolute bottom-0 right-5 flex flex-row items-center gap-2 transition duration-200 group-hover:visible group-hover:opacity-60 hover:!opacity-100">
        <button onClick={onCancel} className="text-xs mb-1 bg-red-500 p-2 py-1 rounded-lg text-white flex flex-row items-center gap-1">
          <BiX />
          <p> cancel</p>
        </button>
      </div>
    )
  }
  return (
    <>
      <div className="absolute bottom-0 right-5 flex flex-row items-center gap-2 invisible opacity-0 transition duration-200 group-hover:visible group-hover:opacity-60 hover:!opacity-100">
        <button onClick={onEdit} className="text-xs mb-1 bg-gray-400 p-2 py-1 rounded-lg text-white flex flex-row items-center gap-1">
          <BiEdit />
          <p> edit</p>
        </button>
        <button onClick={onCopy} className="text-xs mb-1 bg-gray-400 p-2 py-1 rounded-lg text-white flex flex-row items-center gap-1">
          <BiCopy />
          <p> copy</p>
        </button>
        <button onClick={onDelete} className="text-xs mb-1 bg-gray-400 p-2 py-1 rounded-lg text-white flex flex-row items-center gap-1">
          <BsTrash />
          <p> delete</p>
        </button>
      </div>
    </>
  )
}

const ChatBubbleTextToSpeech = memo(({ text = '' }: { text: string }) => {
  const [textSpeech, setTextSpeech] = useAtom(textToSpeechSettingsAtom)
  const { speak, stop, isSpeaking } = useTextToSpeech()

  const onPlay = () => {
    if (isSpeaking) {
      stop()
    } else {
      speak({
        text,
        lang: textSpeech?.language || 'en-US',
        rate: textSpeech?.rate || 1,
        pitch: textSpeech?.pitch || 1,
        voiceURI: textSpeech?.voiceURI,
      })
    }
  }

  const onSettings = () => {
    setTextSpeech({ ...textSpeech, show: true })
  }

  return (
    <div className={`mb-4 -mt-3 ml-3 flex-row items-center justify-between ${!isSpeaking ? 'invisible opacity-0' : ''} transition duration-200 group-hover:visible group-hover:opacity-60 hover:!opacity-100`}>
      <div className="flex flex-row gap-2">
        <Button className={`${isSpeaking ? '!bg-red-500' : '!bg-gray-400'} !py-1 !text-sm mt-2`} onClick={onPlay}>
          {isSpeaking ? (
            <>
              <AiFillPauseCircle />
              <p>Stop</p>
            </>
          ) : (
            <>
              <AiFillPlayCircle />
              <p>Play</p>
            </>
          )}
        </Button>

        <Button className='!bg-gray-400 !py-1 !text-sm mt-2' onClick={onSettings}>
          <p>Voice Settings</p>
        </Button>
      </div>
    </div>
  )
})

