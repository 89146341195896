import { Button, ConditionalRender, SectionSettingsWrapper, Tabs } from "@acme/ui"
import { useRouter } from "next/router"
import { useEffect, useMemo, useState } from "react"

export const RoadmapSection = () => {
  const router = useRouter()
  const [activeOption, setActiveOptionState] = useState<'roadmap' | 'support' | 'faq'>('roadmap')

  const setActiveOption = (option: 'roadmap' | 'support') => {
    if (option === 'roadmap') {
      router.push('/settings?menu=roadmap&tab=roadmap')
    } else if (option === 'support') {
      router.push('/settings?menu=roadmap&tab=support')
    } else if (option === 'faq') {
      router.push('/settings?menu=roadmap&tab=faq')
    }
  }

  const onOpenRoadmap = () => {
    window.open('https://www.notion.so/distartin/54b9ea23c27b4ba68b3bd42ddf11dc81?v=ac24c9b6c382440c88efb0f5333a9b7b&pvs=4', '_blank')
  }

  useEffect(() => {
    if (router.isReady) {
      const { tab } = router.query
      if (tab === 'roadmap') {
        setActiveOptionState('roadmap')
      } else if (tab === 'support') {
        setActiveOptionState('support')
      }
    }
  }, [router.isReady, router.query])

  return (
    <div className="flex flex-col gap-4">
      <SectionSettingsWrapper title="" isShowTitle={false}>
        <Tabs
          tabs={[
            { id: 'roadmap', title: 'Roadmap', content: { test: null } },
            { id: 'support', title: 'Support', content: { test: null } },
            /* { id: 'faq', title: 'FAQ', content: { test: null } }, */
          ]}
          onSelect={(tab) => setActiveOption(tab as 'roadmap' | 'support')}
          activeTabId={activeOption}
          renderTab={(tab) => (
            <div>
            </div>
          )}
        />
        <div className="mb-[-20px]" />
      </SectionSettingsWrapper>

      <ConditionalRender show={activeOption === 'roadmap'}>
        <SectionSettingsWrapper title="Roadmap" isShowTitle={true}>
          <div>
            <div className="bg-gray-700 p-5 rounded-lg">
              <div className="flex flex-row items-center justify-between mb-3">
                <div>
                  <h1 className="text-2xl font-bold text-white">Roadmap</h1>
                  <p className="text-gray-300">We are working on the following features</p>
                </div>
                <div>
                  <Button
                    onClick={onOpenRoadmap}
                  >
                    Click here to Request Feature or Report Bug
                  </Button>
                </div>
              </div>
              <iframe
                src="https://iframe.embednpages.com/R8Cm6tp5prD61BvzXB5k"
                className="w-full h-full min-h-[500px] border-0 p-0 rounded-lg shadow-sm"
              />
            </div>
          </div>
        </SectionSettingsWrapper>
      </ConditionalRender>

      <ConditionalRender show={activeOption === 'support'}>
        <SectionSettingsWrapper title="Support" isShowTitle={true}>
          <div>
            <div className="p-3 rounded-lg text-gray-800">
              <div className="flex flex-col items-start mb-3">
                <div className="mb-5">
                  <p className="text-gray-500">Here to help you</p>
                </div>
                <div>
                  <ul className="list-disc list-inside">
                    <li>
                      <a href="mailto:hi@dappergpt.com" target="_blank" className="text-blue-500 hover:underline">
                        Email us at hi@dappergpt.com
                      </a>
                    </li>
                    {/* <li>
                      <a href="https://twitter.com/distartin" target="_blank" className="text-blue-500 hover:underline">
                        Follow the Creator on Twitter Ryan T - @distartin
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </SectionSettingsWrapper>
      </ConditionalRender>

    </div>
  )
}
