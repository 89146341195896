import axios from 'axios';
import { EventSourceParser } from "eventsource-parser";
import { ChatModel, isModelO1 } from '../constant';
import type { ChatMessage } from '../interface';

export const openai = axios.create({
    baseURL: 'https://api.openai.com/v1',
    headers: {
        'Content-Type': 'application/json'
    }
});

export type GPTChatModel = ChatModel
export type GPTChatMessageContentString = string
export type GPTChatMessageContentText = { type: 'text', text: string }
export type GPTChatMessageContentImage = { type: 'image_url', image_url: { url: string } }
export type GPTChatMessage = { role: ChatMessage['senderRole'], content: ChatMessageContent }
export type ChatMessageContent = GPTChatMessageContentString | (GPTChatMessageContentText | GPTChatMessageContentImage)[]
export type GPTChatMessageWithToken = GPTChatMessage & { tokens?: number }
export type GPTChatResult = {
    id: string,
    object: 'chat.completion',
    created: number,
    choices: {
        index: number,
        message: GPTChatMessage,
        finish_reason: string,
        delta?: {
            content?: string,
            role?: string,
        }
    }[],
    usage: {
        prompt_tokens: number,
        completion_tokens: number,
        total_tokens: number
    }
}
export type OnMessageCallback = (text: string, firstCall?: boolean, lastCall?: boolean) => void

export const openAiChatRequest = async (apiKey: string, model: GPTChatModel, messages: GPTChatMessage[], signal?: AbortController) => {
    // For o1 models, ensure there are no system messages
    let modifiedMessages = messages;
    // change all system messages to developer
    modifiedMessages = messages.map(msg => {
        if (msg.role === 'system') {
            return {
                ...msg,
                role: 'user',
                content: 'developer',
            }
        }
        return msg
    })

    const response = await openai.post<GPTChatResult>(
        '/chat/completions',
        {
            model,
            messages: modifiedMessages,
        }, {
        signal: signal?.signal,
        headers: {
            'Authorization': `Bearer ${apiKey}`,
        },
    })
    return response.data
}

export type OpenAiChatRequestHandlerExtra = {
    onMessage?: OnMessageCallback
    useStreaming?: true;
};

export const openAiChatRequestHandler = async <T extends OpenAiChatRequestHandlerExtra | undefined>(
    apiKey: string,
    model: GPTChatModel,
    messages: GPTChatMessage[],
    signal?: AbortController,
    extra?: T
): Promise<T extends { useStreaming: true } ? null : GPTChatResult> => {
    try {
        if (extra?.useStreaming && extra?.onMessage) {
            await openAiChatRequestStream(apiKey, model, messages, extra.onMessage, signal);
            return null as any;
        } else {
            const request = await openAiChatRequest(apiKey, model, messages, signal);
            return request as any;
        }
    } catch (error: any) {
        let message =
            error?.response?.data?.error?.message || error?.message || 'There are some error';
        if (message.startsWith('Error: ')) {
            message = message.replace('Error: ', '');
        }
        const isModelNotAvailable =
            message.startsWith('The model:') && message.endsWith('does not exist');
        if (isModelNotAvailable) {
            message = `${message}, make sure you have access or allowed to use this model.`;
        }
        throw new Error(message);
    }
};

export const openAiChatRequestStream = async (
    apiKey: string,
    model: GPTChatModel,
    messages: GPTChatMessage[],
    onMessage: OnMessageCallback,
    signal?: AbortController
) => {
    const apiUrl = "https://api.openai.com/v1/chat/completions";

    // For o1 models, ensure there are no system messages
    let modifiedMessages = messages;
    // change all system messages to developer
    modifiedMessages = messages.map(msg => {
        if (msg.role === 'system') {
            return {
                ...msg,
                role: 'user',
                content: 'developer',
            }
        }
        return msg
    })

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${apiKey}`,
        },
        body: JSON.stringify({
            model,
            messages: modifiedMessages,
            stream: true,
        }),
        signal: signal?.signal,
    };

    try {
        const response = await fetch(apiUrl, requestOptions);

        if (response.ok) {
            const reader = response.body!.getReader();
            const decoder = new TextDecoder("utf-8");
            let partialMessage = "";
            let firstCall = true;

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const chunk = decoder.decode(value);
                const messages = chunk.split("\n");

                if (messages.length === 1) {
                    partialMessage += messages[0];
                } else {
                    // Handle partial messages
                    if (messages[0] && messages[0].trim() !== "") {
                        onMessage(partialMessage + messages[0], firstCall);
                        firstCall = false;
                        partialMessage = "";
                    }

                    // Handle completed messages
                    for (let i = 1; i < messages.length - 1; i++) {
                        if (messages[i]) {
                            onMessage(messages[i] || '', firstCall);
                            firstCall = false;
                        }
                    }

                    // Handle DONE message
                    if (messages) {
                        const msgIndex = messages.length - 1
                        // @ts-ignore
                        if (messages[msgIndex] && messages[msgIndex].trim() === "[DONE]") break;
                    }
                }
            }
            onMessage('', false, true)
            return null
        } else {
            const data = await response.json();
            throw new Error(`Error: ${data.error.message}`);
        }
    } catch (error) {
        console.error("Error fetching ChatGPT API:", error);
        throw error;
    }
};

export const openAiTranscribeRequest = async (apiKey: string, audioBlob: Blob): Promise<string> => {
    const formData = new FormData();
    formData.append('file', audioBlob);
    formData.append('model', 'whisper-1');

    const response = await axios.post(
        'https://api.openai.com/v1/audio/transcriptions',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${apiKey}`,
            },
        }
    );

    return response.data.text;
};

export const openAiDallEImageGenerationRequest = async (apiKey: string, prompt: string): Promise<{ url: string }[]> => {
    const response = await axios.post<{ created: number, data: { url: string }[] }>(
        'https://api.openai.com/v1/images/generations',
        {
            prompt: prompt,
            n: 1,
            size: "512x512",
        },
        {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${apiKey}`,
            },
        }
    )

    return response.data?.data as { url: string }[]
}

