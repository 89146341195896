import { useEffect, useMemo } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import { api } from '@acme/client';
import { teamActiveAtom, userAtom, userSubscriptionAtom } from '../store/user';
import { selectAtom } from 'jotai/utils';
import { chatSessionsAtom } from '../store/chat';
import { deepEqual } from '@acme/util';
import { notesAtom } from '../store/note';

const chatSessionsNumsAtom = selectAtom(chatSessionsAtom, chatSessions => {
    const totalSessions = chatSessions.length;
    const totalSyncSessions = chatSessions.filter(session => session.isSync).length;
    return { totalSessions, totalSyncSessions };
}, deepEqual)

const notesNumsAtom = selectAtom(notesAtom, notes => {
    const totalNotes = notes.length || 0;
    const totalSyncNotes = notes.filter(note => note.isSync)?.length || 0;
    return { totalNotes, totalSyncNotes };
}, deepEqual)


export const useUserSubscription = () => {
    const user = useAtomValue(userAtom);
    const teamActive = useAtomValue(teamActiveAtom);
    const { totalSessions, totalSyncSessions } = useAtomValue(chatSessionsNumsAtom);
    const { totalNotes, totalSyncNotes } = useAtomValue(notesNumsAtom)
    const [subscription, setSubscription] = useAtom(userSubscriptionAtom);
    const { data, isLoading, refetch: onRefetch } = api.user.subscription.useQuery(
        { teamId: teamActive?.id || '' },
        { enabled: !!teamActive?.id, staleTime: 8000 }
    );

    const planName = useMemo(() => {
        return subscription?.planPurchase?.plan?.name || data?.planPurchase?.plan?.name || '';
    }, [data?.planPurchase?.plan?.name, subscription?.planPurchase?.plan?.name]);

    useEffect(() => {
        if (data && !isLoading) {
            setSubscription(data);
        }
    }, [data, isLoading, setSubscription]);

    const isSubscribe: null | boolean = useMemo(() => {
        if (!subscription && !teamActive && isLoading) return null;
        if (subscription?.id || data?.id) return true;
        return false;
    }, [data?.id, isLoading, subscription, teamActive]);

    const isAllowAddChatSession = useMemo(() => {
        // user not logged in max 2
        if (!user?.id) {
            return {
                allow: totalSessions < 5,
                allowSync: false,
                message: `You have reached the limit of ${totalSessions} chats, signin to add more.`
            };
        }
        // user logged in max 5 and max sync 1
        if (user?.id && !isSubscribe) {
            return {
                allow: totalSessions < 20 || totalSyncSessions <= 1,
                allowSync: totalSyncSessions <= 1,
                message: `You have reached the limit of ${totalSessions} chats. Max 1 sync chat & 5 chats.`,
            }
        };
        // if lifetime
        if (planName === 'Lifetime') {
            return {
                allow: totalSessions < 300 || totalSyncSessions < 5,
                allowSync: totalSyncSessions < 5,
                message: `You have reached the limit of ${totalSyncSessions} sync chats. Max 2 sync chat.`
            }
        }
        // if subscribed unlimited
        if (isSubscribe) return { allow: true, allowSync: true, message: '' };
        return { allow: false, allowSync: false, message: '' }
    }, [isSubscribe, planName, totalSessions, totalSyncSessions, user?.id]);

    const isAllowAddNote = useMemo(() => {
        // user not logged in max 2
        if (!user?.id) return {
            allow: totalNotes <= 2,
            allowSync: false,
            message: `You have reached the limit of ${totalNotes} notes, signin to add more.`
        };
        // user logged in max 5 and max sync 1
        if (user?.id && !isSubscribe) {
            return {
                allow: totalNotes <= 5 && totalSyncNotes <= 1,
                allowSync: totalSyncNotes < 1,
                message: `You have reached the limit of ${totalNotes} notes.`
            }
        };
        // if lifetime
        if (planName === 'Lifetime') {
            return {
                allow: totalNotes < 200 && totalSyncNotes <= 2,
                allowSync: totalSyncNotes < 2,
                message: `You have reached the limit of ${totalSyncSessions} sync notes. Max 2 sync notes.`
            }
        }
        // if subscribed unlimited
        if (isSubscribe) return { allow: true, allowSync: true, message: '' };
        return { allow: false, allowSync: false, message: '' }
    }, [isSubscribe, planName, totalNotes, totalSyncNotes, totalSyncSessions, user?.id]);

    return {
        planName,
        subscription,
        isSubscribe,
        isLoggedIn: !!user?.id,
        isAllowAddChatSession,
        isAllowAddNote,
        isLoading,
        onRefetch
    };
};