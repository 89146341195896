import dayjs from "dayjs";
import { chatModels, type ChatModel, type ChatModelDb, isModelAllowedVision, isModelAnthropic } from "../constant";
import { type ChatSession, type ChatMessage, type ChatMessageTextContent, type ChatMessageImageURLContent } from "../interface";
import { generateLocalIdByRole } from "./id";
import { type GPTChatMessageWithToken, type GPTChatMessage, type GPTChatMessageContentImage, type GPTChatMessageContentText } from "./openai";
import { type Message, type Prisma } from "@prisma/client"
import { tokenizer } from "./thirdparty";

type MessageModel = ChatModelDb

export const defaultModelStringToPrisma = (model: string): MessageModel => {
    if (chatModels[model as "gpt-4"]) {
        return chatModels[model as "gpt-4"].dbId
    }
    switch (model) {
        // NEW
        case chatModels['gpt-4-1106-preview'].id: return chatModels['gpt-4-1106-preview'].dbId
        case chatModels['gpt-4-vision-preview'].id: return chatModels['gpt-4-vision-preview'].dbId

        // DEFAULT
        case chatModels["gpt-4"].id: return chatModels["gpt-4"].dbId
        case chatModels["gpt-4-32k"].id: return chatModels["gpt-4-32k"].dbId
        case chatModels["gpt-4-0613"].id: return chatModels["gpt-4-0613"].dbId
        case chatModels["gpt-4-32k-0613"].id: return chatModels["gpt-4-32k-0613"].dbId
        case chatModels["gpt-4o"].id: return chatModels["gpt-4o"].dbId
        case chatModels["gpt-4-turbo"].id: return chatModels["gpt-4-turbo"].dbId
        case chatModels["o1-preview"].id: return chatModels["o1-preview"].dbId
        case chatModels["o1-mini"].id: return chatModels["o1-mini"].dbId

        // LEGACY
        case chatModels["gpt-4-0314"].id: return chatModels["gpt-4-0314"].dbId
        case chatModels["gpt-4-32k-0314"].id: return chatModels["gpt-4-32k-0314"].dbId

        // GPT 3.5
        case chatModels["gpt-3.5-turbo"].id: return chatModels["gpt-3.5-turbo"].dbId
        case chatModels["gpt-3.5-turbo-0301"].id: return chatModels["gpt-3.5-turbo-0301"].dbId
        default: return chatModels["gpt-3.5-turbo"].dbId
    }
}

export const defaultModelPrismaToString = (model: MessageModel): ChatModel => {

    if (chatModels[model as "gpt-4"]) {
        return chatModels[model as "gpt-4"].id
    }

    switch (model) {
        // NEW
        case chatModels['gpt-4-1106-preview'].dbId: return chatModels['gpt-4-1106-preview'].id
        case chatModels['gpt-4-vision-preview'].dbId: return chatModels['gpt-4-vision-preview'].id

        // DEFAULT
        case chatModels["gpt-4"].dbId: return chatModels["gpt-4"].id
        case chatModels["gpt-4-32k"].dbId: return chatModels["gpt-4-32k"].id
        case chatModels["gpt-4-0613"].dbId: return chatModels["gpt-4-0613"].id
        case chatModels["gpt-4-32k-0613"].dbId: return chatModels["gpt-4-32k-0613"].id

        // LEGACY
        case chatModels["gpt-4-0314"].dbId: return chatModels["gpt-4-0314"].id
        case chatModels["gpt-4-32k-0314"].dbId: return chatModels["gpt-4-32k-0314"].id

        // GPT 3.5
        case chatModels["gpt-3.5-turbo"].dbId: return chatModels["gpt-3.5-turbo"].id
        case chatModels["gpt-3.5-turbo-0301"].dbId: return chatModels["gpt-3.5-turbo-0301"].id

        // O1
        case chatModels["o1-preview"].dbId: return chatModels["o1-preview"].id
        case chatModels["o1-mini"].dbId: return chatModels["o1-mini"].id

        default: return chatModels["gpt-3.5-turbo"].id
    }
}

export const defaultModelGptLimit = (model: ChatModel): { maxWords: number; maxTokens: number } => {
    if (model === 'gpt-4-turbo' || model === 'gpt-4o') {
        return {
            maxWords: 100000,
            maxTokens: 120000
        }
    } else if (model === 'gpt-4') {
        return {
            maxWords: 7800,
            maxTokens: 7900
        }
    } else if (model === 'gpt-4-32k') {
        return {
            maxWords: 29000,
            maxTokens: 30000
        }
    } else if (model.startsWith('gpt-3.5-turbo')) {
        return {
            maxWords: 15000,
            maxTokens: 16300
        }
    } else if (isModelAnthropic(model)) {
        return {
            maxWords: 180000,
            maxTokens: 200000
        }
    } else {
        return {
            maxWords: 15000,
            maxTokens: 16300
        }
    }
}

// For bubble preview
export const generateChatContentString = (content: ChatMessage['content']) => {
    if (Array.isArray(content)) {
        return content.map(a => a.content).join('\n');
    }
    if (Array.isArray(content.content)) {
        return content.content.join('\n');
    }
    return content?.content || '';
}

// For default instruction shown in chat
export const generateChatStringToContent = (content: string | ChatMessage['content']): ChatMessage['content'] => {
    if (typeof content === 'string') {
        return {
            contentType: 'text',
            content: content
        }
    }
    return content;
}


//~ PARSER
function countWords(text: string | string[]): number {
    if (!text) return 0;
    if (Array.isArray(text)) {
        return text.join(' ').trim().split(/\s+/).length;
    }
    return text.trim().split(/\s+/).length;
}

function countWordsGPTContent(content: GPTChatMessage['content']): number {
    const contentString = getGPTContentToTextString(content)
    return countWords(contentString || '');
}

function countWordsTextContent(content: ChatMessageTextContent): number {
    const contentString = Array.isArray(content?.content)
        ? content.content.join(' ')
        : content.content;

    return countWords(contentString || '');
}

export const getGPTContentToTextString = (gptContent: GPTChatMessage['content']): string => {
    if (typeof gptContent === 'string') {
        return gptContent;
    }
    return gptContent.filter(a => a.type === 'text').map(a => (a as any).text).join(' ');
}

export const getTextContentToTextString = (textContent: ChatMessageTextContent): string => {
    if (!textContent?.content) return ''
    if (typeof textContent?.content === 'string') {
        return textContent?.content;
    }
    if (Array.isArray(textContent?.content)) {
        return textContent?.content.join(' ');
    }
    return ''
}

export const convertGPTContentToChatContent = (gptContent: GPTChatMessage['content']): { content: ChatMessage['content'], contentCustoms?: NonNullable<ChatMessage['contentCustoms']> } => {
    if (typeof gptContent === 'string') return { content: { contentType: 'text', content: gptContent }, contentCustoms: undefined }
    else if (Array.isArray(gptContent)) {
        const contentCustoms = gptContent.filter((a): a is GPTChatMessageContentImage => a.type === 'image_url')
        const content = gptContent.filter((content): content is GPTChatMessageContentText => content.type === 'text').map(a => (a).text).join(' ')
        return {
            content: { contentType: 'text', content },
            contentCustoms: contentCustoms.map(custom => ({
                contentType: 'image_url',
                content: {
                    image_url: {
                        url: custom.image_url.url
                    }
                }
            }))
        }
    }
    return {
        content: { contentType: 'text', content: '' },
        contentCustoms: undefined
    }
}


export function generateGptMessageLimit(messages: ChatMessage[], newMessage: ChatMessage, maxWords: number): GPTChatMessage[] {
    const allMessages = [...messages, newMessage];
    if (allMessages.length < 1) {
        return [];
    }
    let wordCount = 0;
    const gptMessages: GPTChatMessage[] = [];

    const firstMessage = allMessages[0] as ChatMessage;
    wordCount += countWords(firstMessage.content.content || '');

    const lastMessage = allMessages.length > 1 ? allMessages[allMessages.length - 1] : null;
    if (lastMessage) {
        wordCount += countWords(lastMessage.content.content || '');
    }

    if (wordCount > maxWords) {
        throw new Error('First and new messages combined exceed the maximum word limit.');
    }

    // Iterate in reverse order, starting from second the last message
    for (let i = allMessages.length - 2; i > 0; i--) {
        const message = allMessages[i] as ChatMessage;
        const messageWordCount = countWords(message.content.content as string);

        if (wordCount + messageWordCount <= maxWords) {
            gptMessages.unshift({
                role: message.senderRole,
                content: message.content.content as string,
            });
            wordCount += messageWordCount;
        } else {
            const remainingWords = maxWords - wordCount;
            const truncatedContent = truncateContent(message.content, remainingWords);
            gptMessages.unshift({
                role: message.senderRole,
                content: truncatedContent,
            });
            break;
        }
    }
    gptMessages.unshift({
        role: firstMessage.senderRole,
        content: firstMessage.content.content as string,
    });
    if (lastMessage) {
        gptMessages.push({ role: lastMessage.senderRole, content: lastMessage.content.content as string });
    }

    return gptMessages;
}

export const generateGptMessageLimitFromGpt = (gptMessages: GPTChatMessage[], newMessage?: GPTChatMessage, maxWordsPassed?: number): GPTChatMessage[] => {
    const maxWords = maxWordsPassed || 4000;

    const allMessages = newMessage ? [...gptMessages, newMessage] : [...gptMessages];
    let wordCount = 0;
    const outputGptMessages: GPTChatMessage[] = [];

    if (allMessages.length < 1) {
        return [];
    }

    // Add the first message
    const firstMessage = allMessages[0] as GPTChatMessage;
    wordCount += countWordsGPTContent(firstMessage.content);

    // Add the last message or new message
    const lastMessage = allMessages.length > 1 ? allMessages[allMessages.length - 1] : null;
    if (lastMessage) {
        wordCount += countWordsGPTContent(lastMessage.content);
    }

    if (wordCount > maxWords) {
        throw new Error('First and new messages combined exceed the maximum word limit.');
    }


    // Iterate in reverse order, starting from second the last message
    for (let i = allMessages.length - 2; i > 0; i--) {
        const message = allMessages[i] as GPTChatMessage;
        const messageWordCount = countWordsGPTContent(message.content);

        if (wordCount + messageWordCount <= maxWords) {
            outputGptMessages.unshift(message);
            wordCount += messageWordCount;
        } else {
            const remainingWords = maxWords - wordCount;
            const truncatedContent = truncateContentTokens(message.content, remainingWords);
            outputGptMessages.unshift({
                role: message.role,
                content: truncatedContent,
            });
            break;
        }
    }
    outputGptMessages.unshift(firstMessage);
    if (lastMessage) {
        outputGptMessages.push(lastMessage);
    }

    return outputGptMessages;
}

function truncateContent(content: ChatMessage['content'], remainingWords: number): string {
    const contentFixed = getTextContentToTextString(content)
    const words = contentFixed.split(' ');
    let truncated = words.slice(0, remainingWords).join(' ');
    truncated = '..' + truncated.slice(2);

    return truncated;
}

const cacheTokens = new Map<string, number>();

export function messageContentTokens(messageContent: ChatMessageTextContent): number {
    const contentString = Array.isArray(messageContent?.content)
        ? messageContent.content.join(' ')
        : messageContent.content;

    return countTokens(contentString || '');
}

export function messageToMessageToken(message: ChatMessage): ChatMessage {
    if (message?.totalTokens) return message;
    const contentString = Array.isArray(message?.content?.content)
        ? message.content.content.join(' ')
        : message.content.content;

    return {
        ...message,
        totalTokens: countTokens(contentString || ''),
    }
}

export function countTokens(gptContent: GPTChatMessage['content'], tokens?: number): number {
    if (tokens) {
        return tokens;
    }
    const gptContentFixed = getGPTContentToTextString(gptContent)
    if (!cacheTokens.has(gptContentFixed)) {
        cacheTokens.set(gptContentFixed, tokenizer.encode(gptContentFixed).length);
    }
    return cacheTokens.get(gptContentFixed)!;
}

function truncateContentTokens(gptContent: GPTChatMessage['content'], remainingTokens: number): string {
    let tokensCount = 0;
    let tokenIndex = 0;
    let newContent = '';

    const gptContentString = getGPTContentToTextString(gptContent)

    for (const char of gptContentString) {
        if (tokenizer.isWithinTokenLimit(char, 1)) {
            const currentTokens = countTokens(char);
            if (tokensCount + currentTokens <= remainingTokens) {
                tokensCount += currentTokens;
                newContent += char;
                tokenIndex++;
            } else {
                break;
            }
        }
    }
    newContent = '....' + newContent.slice(2);

    cacheTokens.set(newContent, tokensCount);
    return newContent;
}

export const generateGptMessageLimitFromGptTokens = (gptMessages: GPTChatMessageWithToken[], newMessage?: GPTChatMessageWithToken, maxTokensPassed?: number): GPTChatMessage[] => {
    const maxTokens = maxTokensPassed || 4000;

    const allMessages = newMessage ? [...gptMessages, newMessage] : [...gptMessages];
    let tokenCount = 0;
    const outputGptMessages: GPTChatMessage[] = [];

    if (allMessages.length < 1) {
        return [];
    }

    const firstMessage = allMessages[0] as GPTChatMessageWithToken;
    tokenCount += countTokens(firstMessage.content, firstMessage.tokens);

    const lastMessage = allMessages.length > 1 ? allMessages[allMessages.length - 1] : null;
    if (lastMessage) {
        tokenCount += countTokens(lastMessage.content, lastMessage.tokens);
    }

    if (tokenCount > maxTokens) {
        throw new Error('First and new messages combined exceed the maximum token limit.');
    }

    for (let i = allMessages.length - 2; i > 0; i--) {
        const message = allMessages[i] as GPTChatMessageWithToken;
        const messageTokenCount = countTokens(message.content, message.tokens);

        if (tokenCount + messageTokenCount <= maxTokens) {
            outputGptMessages.unshift({
                role: message.role,
                content: message.content,
            });
            tokenCount += messageTokenCount;
        } else {
            const remainingTokens = maxTokens - tokenCount;
            const truncatedContent = truncateContentTokens(message.content, remainingTokens);
            outputGptMessages.unshift({
                role: message.role,
                content: truncatedContent,
            });
            break;
        }
    }
    outputGptMessages.unshift({
        role: firstMessage.role,
        content: firstMessage.content,
    });
    if (lastMessage) {
        outputGptMessages.push({
            role: lastMessage.role,
            content: lastMessage.content,
        });
    }

    return outputGptMessages;
}

//~ GENERATOR
type CompileGptMessagesReturnType<T extends ChatMessage | undefined> = {
    newMessageFixed: T extends ChatMessage ? ChatMessage : undefined;
    gptMessages: GPTChatMessage[];
    initMessage: null;
    initInstruction: string | null;
}

export const compileGptMessages = <T extends ChatMessage | undefined>(session: ChatSession, newMessage: T, maxWords?: number): CompileGptMessagesReturnType<T> => {
    try {
        const initMessage: ChatMessage | null = null
        let initInstruction: string | null = null

        const sessionMessages = session.messages ? [...session.messages] : []
        const refactorMessages: GPTChatMessage[] = sessionMessages.map(a => ({
            content: generateChatContentString(a.content),
            role: a.senderRole,
        })) || []

        //~ PUT INSTRUCTION IN FRONT OF CHAT
        if (session.defaultInstruction) {
            refactorMessages.unshift({
                content: session.defaultInstruction,
                role: "system",
            })
        }


        //~ IF MESSAGE IS EMPTY AND NO INSTRUCTION IN CHAT THEN USE DEFAULT INSTRUCTION
        if (refactorMessages.length === 0 && !session.defaultInstruction) {
            const defaultInstructionMessage = {
                content: "You are a helpful assistant",
                role: "system",
            } as const
            const defaultInstructionChatMessage: ChatMessage = {
                id: generateLocalIdByRole('system'),
                chatSessionId: session.id,
                content: generateChatStringToContent(defaultInstructionMessage.content),
                userId: "",
                senderRole: "developer",
                model: "o1-mini",
                created: dayjs().toDate(),
            }
            sessionMessages.push(defaultInstructionChatMessage)
            refactorMessages.push(defaultInstructionMessage)
            // initMessage = defaultInstructionChatMessage
            initInstruction = defaultInstructionMessage.content
        }

        //~ ADD NEW MESSAGE IF EXISTS
        let newMessageFixed: ChatMessage | undefined = undefined
        if (newMessage) {
            const previousMessage = session.messages.length > 0 ? session.messages[session.messages.length - 1] : null;
            newMessageFixed = {
                ...newMessage,
                ...(previousMessage && previousMessage?.senderRole !== 'system')
                    ? { parentId: previousMessage.id }
                    : {}
            }

            //~ COMBINE WITH USER MESSAGE
            //~ ADD IMAGE IF ALLOWED AND EXIST
            //~ ELSE JUST ADD (DEFAULT)
            if (newMessage?.contentCustoms && session?.defaultModel && isModelAllowedVision(session?.defaultModel)) {
                const imageContents = newMessage?.contentCustoms
                refactorMessages.push({
                    content: [
                        {
                            type: 'text',
                            text: generateChatContentString(newMessage.content),
                        },
                        ...imageContents.map(img => ({
                            type: img.contentType,
                            ...img.content
                        }))
                    ],
                    role: newMessage.senderRole,
                })
            } else {
                refactorMessages.push({
                    content: generateChatContentString(newMessage.content),
                    role: newMessage.senderRole,
                })
            }
        }

        return {
            // Fixed new message format
            newMessageFixed: newMessageFixed as T extends ChatMessage ? ChatMessage : undefined,
            // gpt api messages
            gptMessages: generateGptMessageLimitFromGptTokens(refactorMessages, undefined, maxWords || 3900),
            // previous message if needed
            initMessage,
            // instruction
            initInstruction,
        }
    } catch (error) {
        console.error(error)
        return {
            newMessageFixed: newMessage as unknown as T extends ChatMessage ? ChatMessage : undefined,
            gptMessages: [],
            initMessage: null,
            initInstruction: null,
        }
    }
}

export const messageToChatMessage = (message: Message): ChatMessage => {
    return {
        ...message,
        id: message.localId || message.id,
        parentId: message.parentId || undefined,
        replyToId: message.replyToId || undefined,
        userId: message.userId || undefined,
        model: message.model ? defaultModelPrismaToString(message.model) : undefined,
        updated: message.updated || undefined,
        totalTokens: message.totalTokens || undefined,
    }
}

export const chatMessageToMessage = (cm: ChatMessage): Message => {
    return {
        ...cm,
        localId: cm.localId || cm.id,
        parentId: cm.parentId || null,
        replyToId: cm.replyToId || null,
        userId: cm.userId || null,
        content: {
            content: cm.content.content,
            contentType: cm?.content?.contentType || 'text',
        },
        contentCustoms: null,
        created: typeof cm.created === 'string' ? dayjs(cm.created).toDate() : cm.created,
        updated: cm.updated ? (typeof cm.updated === 'string' ? dayjs(cm.updated).toDate() : cm.updated) : null,
        status: null,
        model: cm.model ? defaultModelStringToPrisma(cm.model) : null,
        totalTokens: cm.totalTokens || null,
    }
}

export const chatMessageManyInputToMessage = (cm: Prisma.MessageCreateManyInput): Message => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return {
        ...cm,
        contentCustoms: (cm.contentCustoms as ChatMessageImageURLContent[]) || null,
        totalTokens: cm.totalTokens || null,
        userId: null,
        parentId: cm?.parentId || null,
        replyToId: cm?.replyToId || null,
        model: cm?.model || null,
        status: cm?.status || null,
        created: cm.created as Date,
        updated: cm.updated as Date,
    }

}


export const chatImageGenPrompts = {
    //instruction: "You are a parser and validator to help generate image, decide from user input and chats if user wants to generate image and generate the prompts. User will share `UserInput` and `ChatHistory` user can provides chats if you ask for more context and if user needs\n\n**Remember, only answer with these exact sentences `Need more context` or `Image prompts:\n-<<list of prompts>>\nMessageReply:`**\n\nExtra info:\n- on the list of image prompts, only generate 1 prompt if user only request one, if user already provide the prompt in the `UserInput` just it on the `Image prompts:` list, and on `MessageReply`, reply to user based on UserInput and ChatHistory if exist",
    //instruction: "You are a parser and validator to help generate image prompts.\n\n**Remember, only answer with this exact sentence `Image prompts:\n-<<list of prompts>>\nMessageReply:<<reply to user input>>` **\n\nExtra:\n- on the list of Image prompts, only generate 1 prompt if user only request one, if user already provide the prompt in the `UserInput` just it on the `Image prompts:` list, and on `MessageReply` reply to user based on UserInput and ChatHistory if exist and no need to re-explain the prompts or share image links",
    instruction: "You are a parser, validator and prompt generator.\n\n**Remember\n- only answer with this exact format `Image prompts:\n-<<list of prompts>>\nMessageReply:<<reply to user input>>`\n- the most important instruction is the `UserInput`**\n\nExtra:\n- on the list of Image prompts, only generate 1 prompt if user only request one, if user already provide the prompt in the `UserInput` just it on the `Image prompts:` list, and on `MessageReply` reply to user based on UserInput and ChatHistory if exist and no need to re-explain the prompts or share image links",
    userInput: "UserInput: <<UserInput>>",
}

export function chatImageGenParseResult(input: GPTChatMessage['content']): { prompts: string[], messageReply: string } {
    const inputFixed = typeof input === 'string' ? input : getGPTContentToTextString(input)
    const lines = inputFixed.split('\n');
    const prompts: string[] = [];
    let messageReply = '';

    let isReadingPrompts = false;

    for (const line of lines) {
        if (line.startsWith('Image prompts:')) {
            isReadingPrompts = true;
            continue;
        }

        if (line.startsWith('MessageReply:')) {
            isReadingPrompts = false;
            messageReply = line.replace('MessageReply:', '').trim();
            continue;
        }

        if (isReadingPrompts) {
            const prompt = line.replace(/^[0-9.-]+/, '').trim();
            if (prompt) prompts.push(prompt);
        }
    }

    return { prompts, messageReply };
}

export const chatFormatImageGen = (userMessage: ChatMessage, gptMessages: GPTChatMessage[], isIncludeChat?: boolean): GPTChatMessage[] => {
    const userMessageContent = Array.isArray(userMessage.content.content) ? userMessage.content.content.join(' ') : userMessage.content.content
    let userInput = chatImageGenPrompts.userInput.replace("<<UserInput>>", userMessageContent || '')
    if (isIncludeChat) {
        const gptMessagesFixed = generateGptMessageLimitFromGptTokens(gptMessages, undefined, 2000)
        const compileChatHistory = gptMessagesFixed
            .filter(a => a.role !== 'system')
            .map((a, index) => {
                const isLastMessage = index === gptMessagesFixed.length - 1
                if (isLastMessage) {
                    if (a?.content === userMessageContent) return ''
                }
                // remove image in markdown format
                const contentParsedOnlyText = getGPTContentToTextString(a.content)
                const contentParsed = contentParsedOnlyText.replace(/!\[[^\]]*\]\([^)]*\)/g, '')
                return `-${a.role}:\`${contentParsed.replace(/(\r\n|\n|\r)/gm, "\\n")}\``
            })
            .join('\n')
        userInput += `\nChatHistory:\n${compileChatHistory}`
    }
    return [
        {
            role: "system",
            content: chatImageGenPrompts.instruction,
        },
        {
            role: "user",
            content: userInput
        }
    ]
}
