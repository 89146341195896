import React, { memo, useEffect, useState } from "react";
import { motion, AnimatePresence, easeOut } from "framer-motion";
import { BiColor, BiExtension, BiRadioCircle, BiRadioCircleMarked, BsBook, BsChat, BsCode, BsImage, BsLock, BsPeople, BsRobot, BsSearch, BsSpeaker, BsStar, BsType, Button, ButtonClose, MdDomain, toast } from "@acme/ui";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { teamActiveAtom, upgradeModalAtom } from "../store/user";
import { type SubscriptionPlan, defaultPlans, yearlySubEqualText, halfYearlySubEqualText, dayjs } from "@acme/util";
import { api } from "@acme/client";
import { subscribe } from "../setting/SettingsSection/Plans";
import { useUserSubscription } from "../hooks";
import { useRouter } from "next/router";
import { signIn } from "next-auth/react"
import Image from "next/image";

const defaultPlan = defaultPlans[0] as SubscriptionPlan;

const mainFeatures: {
  name: string;
  description: string;
  emoji: string;
  icon: JSX.Element;
  video?: string
  gif: string
}[] = [
    {
      name: 'Better Chat UI',
      description: 'Better ChatGPT UI with more features and better experience',
      emoji: '💬',
      icon: <BsChat className="text-blue-600" />,
      video: '/landing/feature-1-chat.mp4',
      gif: '/landing/feature-1-chat.gif'
    },
    {
      name: 'AI-Powered Notes',
      description: 'Save your notes as you want 200 notes',
      emoji: '📝',
      icon: <BsBook className="text-blue-600" />,
      gif: '/landing/feature-2-note.gif'
    },
    {
      name: 'Chrome Extension',
      description: 'Use the chrome extension to generate response by AI',
      emoji: '🤖',
      icon: <BiExtension className="text-blue-600" />,
      video: '/landing/feature-3-extension.mp4',
      gif: '/landing/feature-3-extension.gif'
    },
  ]

const features: {
  name: string;
  description: string;
  emoji: string;
  icon: JSX.Element | string;
  gif: string;
}[] = [
    {
      name: 'Smart Search',
      description: 'Search for messages & notes easily',
      emoji: '🔍',
      icon: <BsSearch className="text-blue-600" />,
      gif: '/landing/subfeature-search.gif'
    },
    {
      name: 'Save to Cloud',
      description: 'Option to store to cloud for multi device use',
      emoji: '🔒',
      icon: <BsLock className="text-blue-600" />,
      gif: '/landing/subfeature-cloud.gif'
    },
    {
      name: 'Favorite Chats',
      description: 'Pin your Favorite Chat to the top',
      emoji: '⭐',
      icon: <BsStar className="text-blue-600" />,
      gif: '/landing/subfeature-favorite.gif'
    },
    {
      name: 'AI Image Generate',
      description: 'Generate Image from your Dall-E directly in the chat',
      emoji: '🖼️',
      icon: <BsImage className="text-blue-600" />,
      gif: '/landing/subfeature-imagegen.gif'
    },
    {
      name: 'Color Highlight',
      description: 'Highlight Color and Copy to Clipboard Fast',
      emoji: '...',
      icon: <BiColor className="text-blue-600" />,
      gif: '/landing/subfeature-color.gif'
    },
    {
      name: 'Domain Checker',
      description: 'Highlight Domain and Check the availability fast',
      emoji: '...',
      icon: <MdDomain className="text-blue-600" />,
      gif: '/landing/subfeature-domain.gif'
    },
    {
      name: 'Code Highlight',
      description: 'Highlight your code in the chat with copy to clipboard',
      emoji: '⭐',
      icon: <BsCode className="text-blue-600" />,
      gif: '/landing/subfeature-code.gif'
    },
    {
      name: 'Character Instruction',
      description: 'Select or Set your AI to be a specific character',
      emoji: '👤',
      icon: <BsRobot className="text-blue-600" />,
      gif: '/landing/subfeature-instruction.gif'
    },
    {
      name: 'Prompts',
      description: 'Select or Set your AI Prompts',
      emoji: '...',
      icon: <BsType className="text-blue-600" />,
      gif: '/landing/subfeature-prompt.gif'
    },
    {
      name: 'Preview Code',
      description: 'Show the chat code preview for advanced implementation',
      emoji: '...',
      icon: <BsType className="text-blue-600" />,
      gif: '/landing/subfeature-showcode.gif'
    },
    {
      name: 'Note Custom Prompt & Instruction',
      description: 'Customize your note prompt format and instruction',
      // note emoji
      emoji: '📝',
      icon: <BsBook className="text-blue-600" />,
      gif: '/landing/subfeature-notecustom.gif'
    },
    {
      name: 'Text to Speech',
      description: 'Speak to fill the chat',
      // speak emoji
      emoji: '🗣',
      icon: <BsSpeaker className="text-blue-600" />,
      gif: '/landing/subfeature-speech.gif'
    },
    {
      name: 'Profile Extension',
      description: 'Set Profiles for your Chrome Extension to autofill gmail & post on popular sites with different tone',
      // people emoji
      emoji: '👥',
      icon: <BsPeople className="text-blue-600" />,
      gif: '/landing/subfeature-chromeprofile.gif'
    },
    {
      name: 'Many Others & Future Updates',
      description: `Exciting future updates such as domain assign, team members, chat background, public chat, slack integrations, etc`,
      emoji: '🚀',
      icon: '🚀',
      gif: ''
    }
  ]

export const UpgradeModal: React.FC = () => {
  const router = useRouter()
  const teamActive = useAtomValue(teamActiveAtom)
  const [{ isOpen, lastOpen }, setUpgradeModal] = useAtom(upgradeModalAtom);
  const { isSubscribe, isLoading, isLoggedIn } = useUserSubscription()

  const onClose = () => {
    setUpgradeModal({ isOpen: false, lastOpen: new Date() });
  };

  useEffect(() => {
    if (router?.isReady) {
      // If team exist but not subscribe
      if (teamActive?.id && !isSubscribe && !isLoading) {
        if (!isOpen && !lastOpen && !isSubscribe) {
          setUpgradeModal({ isOpen: true, lastOpen: new Date() });
        }

        if (!isSubscribe) {
          const intervalId = setInterval(() => {
            // ten minutes
            const fifteenMinutes = 10 * 60 * 1000;
            const currentTime = new Date();

            if (
              teamActive?.id &&
              !isSubscribe &&
              !isLoading &&
              !isOpen &&
              lastOpen &&
              (currentTime.getTime() - dayjs(lastOpen).toDate().getTime()) > fifteenMinutes
            ) {
              setUpgradeModal({ isOpen: true, lastOpen: new Date() });
            }
          }, 1000); // Check every second, you can adjust this value if needed

          return () => {
            clearInterval(intervalId); // Clean up the interval on unmount
          }
        }

        // If team not exist (not logged in)
      } else if (!teamActive?.id && !isLoading) {
        if (!isOpen && !lastOpen) {
          setUpgradeModal({ isOpen: true, lastOpen: new Date() })
        }

        const intervalId = setInterval(() => {
          const fifteenMinutes = 10 * 60 * 1000;
          const currentTime = new Date();

          if (
            !isOpen &&
            lastOpen &&
            (currentTime.getTime() - dayjs(lastOpen).toDate().getTime()) > fifteenMinutes
          ) {
            setUpgradeModal({ isOpen: true, lastOpen: new Date() });
          }
        }, 1000); // Check every second, you can adjust this value if needed

        return () => {
          clearInterval(intervalId); // Clean up the interval on unmount
        }
      }
    }
  }, [isLoading, isOpen, isSubscribe, lastOpen, setUpgradeModal, teamActive?.id, router?.isReady]);

  useEffect(() => {
    if (router?.isReady && !isSubscribe && !isOpen && router?.query?.upgrade) {
      setUpgradeModal({ isOpen: true, lastOpen: new Date() })
      // remove the upgrade query only, make sure not remove other query
      const { upgrade, ...query } = router.query
      router.replace({ pathname: router.pathname, query }, undefined, { shallow: true })
    }
  }, [router?.isReady, isSubscribe, isLoading, router?.query?.upgrade, router, isOpen, setUpgradeModal])

  if (!isOpen) return null;
  return (
    <UpgradeModalComponent
      isOpen={isOpen}
      onClose={onClose}
      plans={defaultPlans}
    />
  );
};

type UpgradeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  // onUpgrade: (plan: PlanProps) => void;
  plans?: SubscriptionPlan[];
};


export const UpgradeModalComponent: React.FC<UpgradeModalProps> = memo(({
  isOpen,
  onClose,
  plans = defaultPlans,
}) => {
  const [selectedFeature, setSelectedFeature] = useState(0)
  const teamActive = useAtomValue(teamActiveAtom)
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(0)
  const [isSubLoading, setIsSubLoading] = useState(false)
  const [isOpenMorePlan, setIsOpenMorePlan] = useState(false)
  const setUpgradeModal = useSetAtom(upgradeModalAtom);

  const selectedPlan = plans[selectedPlanIndex]
  const { data: session } = api.auth.getSession.useQuery()

  const { name, price, discount, interval } = (selectedPlan || defaultPlan) satisfies SubscriptionPlan;
  const discountedPrice = discount ? ((price * (100 - discount)) / 100).toFixed(2) : price;
  const monthlyPrice = (Number(discountedPrice) / (interval === 'halfyearly' ? 6 : 12)).toFixed(2);

  const backdrop = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  const modal = {
    hidden: { y: "-50vh", opacity: 0 },
    visible: { y: "0", opacity: 1, transition: { delay: 0.5 } },
  };

  const handleTabClick = (index: number) => {
    setSelectedPlanIndex(index);
  };

  const onUpgrade = async (planId: string) => {
    if (session) {
      try {
        setIsSubLoading(true)
        // Trigger the Stripe Checkout process here
        console.log("Initiating checkout for plan:", planId);
        setTimeout(() => {
          setIsSubLoading(false)
        }, 5000);
        await subscribe(planId, teamActive?.id || '');
        setIsSubLoading(false)
        setUpgradeModal({ isOpen: false, lastOpen: new Date() });
      } catch (error) {
        console.error("Error during checkout:", error);
        toast.error('Error during checkout')
        setIsSubLoading(false)
      }
    } else {
      toast.error('Sign In to upgrade your account')
      setTimeout(() => {
        signIn('', { callbackUrl: '/settings?menu=plan' })
      }, 1000);
    }
  };

  return (
    <AnimatePresence mode="wait">
      {isOpen && (
        <motion.div
          className="fixed py-5 inset-0 z-[100000] flex items-center justify-center bg-gray-900 bg-opacity-50 h-screendvh max-h-screendvh overflow-y-auto"
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={backdrop}
          transition={{ duration: 0.3 }}
          onClick={onClose}
        >
          <motion.div
            className="bg-white pt-12 rounded-lg shadow-xl w-full max-w-lg m-auto relative"
            variants={modal}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="">
              <ButtonClose
                onClick={onClose}
                className="!top-1 !right-2 z-50"
              />
              <div className="w-full flex justify-start text-right absolute left-2 top-2">
                <div className="bg-red-500 p-2 py-1 rounded-lg text-white font-bold text-sm">
                  40% OFF
                </div>
              </div>
              <h2 className="text-2xl font-bold mb-4 text-center">Limited Offer</h2>
              <div className="flex justify-center">
                <div className="rounded-lg p-1 px-1 flex flex-row gap-2 items-center flex-1 justify-center">
                  {plans.map((plan, index) => {
                    const isSelected = index === selectedPlanIndex;
                    return (
                      <button
                        key={plan.id}
                        className={`px-4 py-1 rounded-lg min-w-[150px] flex flex-row gap-1 items-center ${isSelected
                          ? "bg-blue-100 text-blue-700 font-bold border-2 border-blue-500"
                          : "bg-white text-blue-500 border-2 border-transparent"
                          }`}
                        onClick={() => handleTabClick(index)}
                      >
                        {isSelected ? <BiRadioCircleMarked /> : <BiRadioCircle />}
                        <p>
                          {plan?.shortName || plan.interval}
                        </p>
                      </button>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="px-7 pb-5 pt-5 bg-neutral-50 rounded-b-lg">
              <p className="mb-6 text-center">
                {discount ? (
                  <>
                    <span className="line-through text-gray-500 text-2xl">${price}</span>{" "}
                    <span className="text-blue-700 font-bold text-4xl">${discountedPrice}</span>
                  </>
                ) : (
                  <>
                    <span className="text-blue-700 font-bold text-4xl">${discountedPrice}</span>
                  </>
                )}
              </p>

              {interval !== 'lifetime' ? (
                <>
                  <div className="relative">
                    <p className="mb-6 text-center relative">
                      Only <span className="text-blue-600">${monthlyPrice}/month</span>
                    </p>
                    <div className="flex items-center justify-center">
                      <RotatingText planInterval={interval as 'yearly'} />
                    </div>
                  </div>
                  <p className="mt-4 text-center mb-5">
                    Secure this limited beta offer & <span className="font-smeibold">🔒lock</span> in the discount price and for future renewals.
                  </p>

                  <p
                    className={`mt-4 text-center mb-3 whitespace-pre-line cursor-pointer select-none ${isOpenMorePlan ? 'font-bold' : ''} hover:underline`}
                    onClick={() => setIsOpenMorePlan(!isOpenMorePlan)}
                  >
                    {`Get access to ${isOpenMorePlan ? '⬆️' : '⬇️'}`}
                  </p>
                  <motion.div
                    layout
                    transition={{ duration: 0.3 }}
                  >
                    {isOpenMorePlan && (
                      <ul className="gap-1 flex flex-col mb-5 ml-20">
                        <li className="">All future updates and features</li>
                        <li className="">Priority Support</li>
                        <li className="">Up-to 100 cloud sync chats & notes</li>
                        <li className="">Chrome Extension Profile</li>
                        <li className="">Custom Deployment to your Domain</li>
                        <li className="">Invite Team Members (coming soon)</li>
                        <li className="">Public Chat (coming soon)</li>
                        <li className="">Customize Chat Background (coming soon)</li>
                      </ul>
                    )}
                  </motion.div>

                  <p className="mt-4 text-center mb-5 font-bold">
                    3 days money back guarantee.
                  </p>
                </>
              ) : (
                <>
                  <p className="mt-4 text-center mb-5">
                    This lifetime offer is only limited seats<br />+ 40% OFF<br />
                  </p>
                  <p
                    className={`mt-4 text-center mb-3 whitespace-pre-line cursor-pointer select-none ${isOpenMorePlan ? 'font-bold' : ''} hover:underline`}
                    onClick={() => setIsOpenMorePlan(!isOpenMorePlan)}>
                    {`Get access to ${isOpenMorePlan ? '⬆️' : '⬇️'}`}
                  </p>
                  <motion.div
                    layout
                    transition={{ duration: 0.3 }}
                  >
                    {isOpenMorePlan && (
                      <ul className="gap-1 flex flex-col mb-5 ml-20">
                        <li className="">Unlimited chats, notes, chrome extension</li>
                        <li className="">All future updates and features</li>
                        <li className="">Support</li>
                        <li className="">Up-to 2 cloud sync chats & notes</li>
                        <li className="">Option to add-on plus feature with much cheaper price</li>
                        <li className="">Customize Chat Background (coming soon)</li>
                      </ul>
                    )}
                  </motion.div>
                </>
              )}

              <Button
                loading={isSubLoading}
                className="bg-orange-600 text-white font-bold px-4 text-lg py-3 rounded-lg shadow-md hover:bg-blue-700 w-full mb-4"
                onClick={() => selectedPlan ? onUpgrade(selectedPlan?.id || '') : {}}
              >
                🎉  Get & Unlock All Features
              </Button>

              <h3 className="text-xl font-bold mb-4 text-center mt-10">Main:</h3>
              <div className="grid grid-cols-2 lg:grid-cols-2 gap-2 mb-5 cursor-pointer">
                {mainFeatures.map((feature, fIndex) => {
                  const isSelected = selectedFeature === fIndex + 1
                  return (
                    <motion.div
                      layout
                      initial={{ gridColumn: 'span 1' }}
                      animate={isSelected ? { gridColumn: 'span 2' } : { gridColumn: 'span 1' }}
                      transition={{ duration: 0.2, ease: "easeIn" }}
                      onClick={() => setSelectedFeature(fIndex + 1)}
                      key={feature.name}
                      className={`cursor-pointer bg-blue-50 border-[1px] border-blue-100 p-2 gap-2 rounded-lg hover:scale-105 ${isSelected ? 'col-span-2 !bg-blue-200/60 border-blue-500' : ''}`}
                    >
                      <div className="flex items-center">
                        <span className="text-2xl mr-2">
                          {feature.icon}
                        </span>
                        <div>
                          <h4 className="font-medium text-blue-700">{feature.name}</h4>
                        </div>
                      </div>
                      {feature?.video ? (
                        <>
                          {isSelected && <p className="text-gray-500">{feature.description}</p>}
                          <video
                            autoPlay
                            muted
                            controls={isSelected}
                            src={feature.video}
                            className="object-contain rounded mt-3 w-full h-auto"
                          />
                        </>
                      ) : isSelected ? (
                        <>
                          <p className="text-gray-500">{feature.description}</p>
                          <Image
                            width={500}
                            height={300}
                            src={feature.gif} alt={feature.name}
                            className="object-contain rounded mt-3 w-full h-auto"
                          />
                        </>
                      ) : (
                        <Image
                          width={200}
                          height={100}
                          src={feature.gif} alt={feature.name}
                          className="object-contain rounded mt-3 w-full h-auto"
                        />
                      )}
                    </motion.div>
                  )
                })}
              </div>
              <h3 className="text-xl font-bold mb-4 text-center">Features:</h3>
              <div className="grid grid-cols-2 lg:grid-cols-2 gap-3 mb-5">
                {features.map((feature, fIndex1) => {
                  const fIndex = fIndex1 + 3
                  const isSelected = selectedFeature === fIndex + 1
                  return (
                    <motion.div
                      layout
                      initial={{ gridColumn: 'span 1' }}
                      animate={isSelected ? { gridColumn: 'span 2' } : { gridColumn: 'span 1' }}
                      transition={{ duration: 0.2, ease: "easeIn" }}
                      onClick={() => setSelectedFeature(fIndex + 1)}
                      key={feature.name}
                      className={`cursor-pointer bg-blue-50 border-[1px] border-blue-100 p-2 gap-2 rounded-lg hover:scale-105 ${isSelected ? 'col-span-2 !bg-blue-200/60 border-blue-500' : ''}`}
                    >
                      <div className="flex items-center">
                        <span className="text-2xl mr-2">
                          {feature.icon}
                        </span>
                        <div>
                          <h4 className="font-medium text-blue-700">{feature.name}</h4>
                        </div>
                      </div>
                      {isSelected ? (
                        <>
                          <p className="text-gray-500">{feature.description}</p>
                          {feature?.gif && (
                            <Image
                              width={500}
                              height={300}
                              src={feature.gif} alt={feature.name}
                              className="object-contain rounded mt-3 w-full h-auto"
                            />
                          )}
                        </>
                      ) : feature?.gif ? (
                        <Image
                          width={200}
                          height={100}
                          src={feature.gif} alt={feature.name}
                          className="object-contain rounded mt-3 w-full h-auto"
                        />
                      ) : null}
                    </motion.div>
                  )
                })}
              </div>
              <button
                className="text-gray-500 w-full"
                onClick={onClose}
              >
                Skip
              </button>
            </div>
          </motion.div>
        </motion.div>
      )
      }
    </AnimatePresence >
  );
});
UpgradeModalComponent.displayName = "UpgradeModalComponent";

type RotatingTextProps = {
  planInterval: "yearly" | "halfYearly";
};

export const RotatingText: React.FC<RotatingTextProps> = ({ planInterval }) => {

  const [currentIndex, setCurrentIndex] = useState(0);
  const textList = planInterval === "yearly" ? yearlySubEqualText : halfYearlySubEqualText;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % textList.length);
    }, 2000);
    return () => clearInterval(interval);
  }, [textList.length]);

  return (
    <span className="relative" style={{ display: "inline-block", width: "280px", height: 20 }}>
      <AnimatePresence>
        {textList.map((item, index) => (
          <motion.span
            key={item.key}
            initial={{ opacity: 0 }}
            animate={{ opacity: index === currentIndex ? 1 : 0 }}
            exit={{ opacity: 0 }}
            className="text-gray-800 font-semibold text-center"
            transition={{ duration: 0.5 }}
            style={{
              position: "absolute",
              top: -20,
              left: 0,
              width: "100%",
              overflow: "hidden",
            }}
          >
            {item.text}
          </motion.span>
        ))}
      </AnimatePresence>
    </span>
  );
};
